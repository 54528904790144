






















































































































































































































import Vue from 'vue';

// WEB{License}{Pacakge}={Copyright}
const WEB = {
  'Apache2.0': {
    ably: '2015-2020 Ably Real-time Ltd',
    localForage: '2013-2017 Mozilla',
    snyk: '2015 Snyk Ltd.',
  },
  'BSD-3': {
    '@sentry/browser': '2019 Sentry',
    '@sentry/hub': '2019 Sentry',
    '@sentry/integrations': '2019 Sentry',
    '@sentry/types': '2019 Sentry',
    '@sentry/utils': '2019 Sentry',
    ieee754: '2008 Fair Oaks Labs, Inc.',
  },
  'BSD-0': {
    tslib: '2020 Microsoft Corporation',
  },
  MIT: {
    '@stripe/stripe-js': '2017 Stripe',
    'animate.css': '2020 Daniel Eden',
    axios: '2014-present Matt Zabriskie',
    'base64-js': '2014 Jameson Little',
    buffer: '2014 Feross Aboukhadijeh',
    bulma: '2019 Jeremy Thomas',
    'bulma-checkradio': '2017 Wikiki',
    'bulma-pricingtable': '2017 Wikiki',
    'core-js': '2014-2020 Denis Pushkarev',
    'file-icon-vectors': '2018 Daniel M. Hendricks',
    feathericons: '2013-2017 Cole Bemis',
    isarray: '2013 Julian Gruber',
    'jwt-decode': '2015 Auth0, Inc.',
    tailwindcss: 'Adam Wathan, Jonathan Reinink',
    vue: '2013-present Yuxi (Evan) You',
    'vue-i18n': '2016 kazuya kawaguchi',
    'vue-meta': '2016-2019 Declan de Wet, Sébastien Chopin, All the amazing contributors',
    'vue-toast-notification': 'Ankur Kumar',
    vuex: '2015-present Evan You',
    'v-money': '2017 Marcos Neves',
  },
};

export default Vue.extend({
  computed: {
    apache2() {
      return WEB['Apache2.0'];
    },
    bsd3() {
      return WEB['BSD-3'];
    },
    bsd0() {
      return WEB['BSD-0'];
    },
    mit() {
      return WEB.MIT;
    },
  },
  meta() {
    return {
      title: this.$t('title').toString(),
    };
  },
});
